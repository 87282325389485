import { z } from 'zod';

export const LoginSchema = z.object({
    redirectTo: z.string().optional(),
    email: z
        .string()
        .email('Email address is invalid')
        .nonempty('Email is required'),
    password: z.string().nonempty('Password is required'),
});

export const SignupSchema = z.object({
    redirectTo: z.string().optional(),
    display_name: z
        .string()
        .min(2, 'Display name needs to be between 2 to 40 characters')
        .max(40, 'Display name needs to be between 2 to 40 characters')
        .nonempty('Display name is required'),
    email: z
        .string()
        .email('Please enter your email')
        .nonempty('Email is required'),
    password: z.string().regex(/^(?=.*[A-Z])(?=.*\d).{10,}$/, {
        message:
            'Must contain at least 10 characters with 1 uppercase and 1 number',
    }),
});

export const ResetPasswordSchema = z.object({
    email: z
        .string()
        .email('Email address is invalid')
        .nonempty('Email is required'),
});

export const CreateNewPasswordSchema = z.object({
    code: z.string(),
    password: z.string().regex(/^(?=.*[A-Z])(?=.*\d).{10,}$/, {
        message:
            'Must contain at least 10 characters with 1 uppercase and 1 number',
    }),
});
